import {
    SET_CURRENT_USER,
    SET_USER_LOADING,
    SET_USER_NOT_LOADING,
    LOGIN,
    LOGOUT
} from '../actions/types'

const isEmpty = require("is-empty");

const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    access_token:null,
    refresh_token:null
}
export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated:true,
                profile:action.payload.profile,
                token:action.payload.token
            }
            break;
      case LOGOUT:
            return {
                ...state,
                isAuthenticated:false,
                user:{},
                profile:{},
                token:null
            }
      case SET_CURRENT_USER:
        return {
          ...state,
          isAuthenticated: !isEmpty(action.payload),
          user: action.payload.decoded,
          access_token:action.payload.access_token,
          refresh_token:action.payload.refresh_token
        };
      case SET_USER_LOADING:
        return {
          ...state,
          loading: true
        };
      case SET_USER_NOT_LOADING:
        return {
          ...state,
          loading:false
        }
  
      default:
        return state;
    }
  }