import {baseUrl} from '../config.js';
import axios from 'axios';
import store from '../redux/store.js';
class API {
    fetch({method,endpoint,data}) {
        return new Promise(function(resolve,reject){
            axios({
                method:method,
                url: baseUrl + endpoint,
                data:data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + store.getState().auth.token
                  }
            }).then(result=>{
                resolve(result);

            }).catch(error=>{
                reject(error)
            })
        })
    }
}

export default new API();